import React, { useEffect } from 'react';
import { isBrowser } from '@utils';

const Blog = () => {
  useEffect(() => {
    if (isBrowser()) {
      window.location.href = 'https://blog.hyperhuman.cc';
    }
  });
  return <div></div>;
};

export default Blog;
